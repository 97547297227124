import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.update(this.selectTarget.selectedOptions[0].value);
  }

  select() {
    this.update(this.selectTarget.selectedOptions[0].value);
  }

  update(value) {
    this.element.querySelectorAll("[data-fields-for]").forEach(el => {
      el.classList.toggle("d-none", el.dataset.fieldsFor != value);
    });
  }
}
