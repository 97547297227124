import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    // bootstrap
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $("[data-toggle='toast']").toast("show");
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('[data-bs-toggle="popover"]').popover();
    $("[data-bs-toggle='toast']").toast("show");

    $(".select2").select2();

    // missing translation
    if (console && !console.log) {
      $(".translation_missing").each(function () {
        console.log($(this).attr("title"));
      });
    }

    $("[data-toggle='remove']").each(function(index, el){
      $(el).on("click", function (e) {
        $(el).parents($(el).data("target")).remove();
        Rails.stopEverything(e);
        return false;
      });
    });

    var ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };

    $("[data-datetimerange]").daterangepicker({
      timePicker: true,
      timePickerIncrement: 5,
      autoUpdateInput: false,
      timePicker24Hour: true,
      locale: {
        format: 'YYYY-MM-DD hh:mm:ss'
      },
      ranges: ranges
    });

    $("[data-datetimerange]").on('apply.daterangepicker', function(ev, picker) {
      var end, start;
      start = picker.startDate.format(picker.locale.format);
      end = picker.endDate.format(picker.locale.format);
      picker.element.val(start + " - " + end);
      return picker.element.trigger("change");
    });

    $("[data-daterange]").each(function() {
      var cfg;
      cfg = {
        timePicker: false,
        autoUpdateInput: false,
        locale: {
          format: 'YYYY-MM-DD'
        },
        ranges: ranges
      };
      return $(this).daterangepicker($.extend(cfg, $(this).data()));
    });

    $("[data-daterange]").on('apply.daterangepicker', function(ev, picker) {
      var end, start;
      start = picker.startDate.format(picker.locale.format);
      end = picker.endDate.format(picker.locale.format);
      picker.element.val(start + " - " + end);
      return picker.element.trigger("change");
    });

    $("[data-datepicker]").daterangepicker({
      timePicker: false,
      singleDatePicker: true,
      autoUpdateInput: true,
      autoApply: true,
      drops: "auto",
      locale: {
        format: 'YYYY-MM-DD'
      }
    });
    // $("[data-datepicker]").on("apply.daterangepicker", function (ev, picker) {
    //   debugger;
    //   var date;
    //   date = picker.startDate.format(picker.locale.format);
    //   picker.element.val("" + date);
    //   return picker.element.trigger("change");
    // });

    $("[data-datetimepicker]").daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      autoUpdateInput: true,
      timePickerSeconds: false,
      locale: {
        format: 'YYYY-MM-DD HH:MM'
      }
    });

    $("[data-datetimepicker]").on('apply.daterangepicker', function(ev, picker) {
      var date;
      date = picker.startDate.format(picker.locale.format);
      picker.element.val("" + date);
      return picker.element.trigger("change");
    });
  }
}
