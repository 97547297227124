var rails = require("@rails/ujs")
rails.start()
window.Rails = global.Rails = rails
require("turbolinks").start()
require("@rails/activestorage").start()

import "vendor/bootstrap/bootstrap"
import "vendor/bootstrap/bootstrap.scss"

import "select2/dist/js/select2.full";
import "select2/dist/css/select2.css";
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";
$.fn.select2.defaults.set("theme", "bootstrap-5");

var urijs = require("urijs");
global.URI = window.URI = urijs;

var moment = require("moment");
global.moment = window.moment = moment;

import "daterangepicker";
import "daterangepicker/daterangepicker.css";

import "plyr/src/sass/plyr.scss";

var app = require("scripts/app").default;
global.app = window.app = app;

import { delegate } from "jquery-events-to-dom-events";
delegate('apply.daterangepicker');
delegate('change');

import "controllers"
import "styles"
