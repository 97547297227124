import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  show () {
    var modal = $(this.modalHTML());
    modal.appendTo(document.body);
    modal.modal("show").on("hidden.bs.modal", function () {
      $("#lightbox-modal").remove();
    });
  }

  modalHTML () {
    var html =
      '\
      <div class="modal" role="dialog" id="lightbox-modal">\
        <div class="modal-dialog modal-xl">\
          <div class="modal-content">\
            <div class="modal-header">\
              <div>\
                <h4 class="modal-title">$TITLE</h4>\
              </div>\
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>\
            </div>\
            <div class="modal-body text-center">\
              <img src="$URL" class="img-fluid" />\
            </div>\
          </div>\
        </div>\
      </div>\
    ';
    html = html.replace("$TITLE", this.data.get("title") || " ");
    html = html.replace("$URL", this.data.get("url"));
    return html;
  }
}
