import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cardInput", "customerInput", "subscriptionInput"];
  static values = {  };

  selectCustomer(e) {
    this.customerInputTarget.value = e.detail.id;
    this.customerInputTarget.form.submit();
  }

  selectSubscription(e) {
    this.subscriptionInputTarget.value = e.detail.id;
    this.subscriptionInputTarget.form.submit();
  }
}
