import { Controller } from "stimulus";
import { debounce } from "debounce";

export default class extends Controller {
  static targets = ["container", "results"]
  static values = { url: String, type: String }

  initialize() {
    this.search = debounce(this.search, 500).bind(this);
  }

  connect() {
    const ctrl = this;
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      success: function (response) {
        ctrl.containerTarget.innerHTML = response.body.innerHTML;
      }
    });
  }

  search(e) {
    if (e.target.value == "") {
      this.resultsTarget.innerHTML = "";
      return;
    }
    const ctrl = this;
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      data: new URLSearchParams({ q: e.target.value }),
      success: function (response) {
        ctrl.resultsTarget.innerHTML = response.body.innerHTML;
      },
      error: function () {
        ctrl.resultsTarget.innerHTML = "";
      },
    });
  }

  openLink(e) {
    let link = this.parentLink(e.target);
    if (!link) return;
    let ctrl = this;
    if (link.dataset.recordSelectorLink) {
      Rails.ajax({
        type: "GET",
        data: "selector=true",
        url: link.getAttribute("href"),
        success: function (response) {
          ctrl.containerTarget.innerHTML = response.body.innerHTML;
        }
      })
    }
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  parentLink(element) {
    if (!element || element.tagName == "A")
      return element;
    return this.parentLink(element.parentElement)
  }

  displayAjaxResult(e) {
    this.containerTarget.innerHTML = e.detail[2].responseText
    var autoSelectElement = this.containerTarget.querySelector("[data-record-selector-autoselect=true]")
    if (autoSelectElement) {
      this.select({ target: autoSelectElement })
    }
  }

  select(e) {
    let event = new CustomEvent(
      "record:selected:" + this.typeValue,
      {
        bubbles: true,
        cancelable: true,
        detail: {
          id: e.target.dataset.id,
          name: e.target.dataset.name,
        }
      }
    )
    this.element.dispatchEvent(event)
  }
}
