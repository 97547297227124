import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  open() {
    const url = this.data.get("url");
    if (url == "") {
      return;
    }
    app.load(url);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  openFromSelect() {
    const url = document.querySelector("option:checked", this.element).dataset.url;
    if (url == "") {
      return;
    }
    app.load(url);
  }

  openFromInput(event) {
    var url = URI(location.href);
    url.removeSearch(event.target.name);
    url.addSearch(event.target.name, event.target.value);
    app.load(url.toString());
  }
}
