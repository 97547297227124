import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.reset();
  }

  reset() {
    this.lasttime = undefined
    this.maxdelta = 0
    this.inputTarget.value = ""
  }

  input(e) {
    if (this.data.get("freeinput") == "true") return true;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.check() }, 150);
    if (e.keyCode < 48 || e.keyCode > 57) {
      Rails.stopEverything(e);
      return false;
    }
    this.lasttime = this.lasttime || +new Date();
    var newtime = +new Date()
    var delta = newtime - this.lasttime
    this.maxdelta = Math.max(this.maxdelta, delta)
    this.lasttime = newtime
    if(this.maxdelta > 100) {
      this.reset()
      Rails.stopEverything(e)
      return false
    }
  }

  check() {
    if (this.inputTarget.value.length < 10) {
      this.reset();
    } else {
      this.element.submit();
    }
  }

  submit(e) {
    if (this.data.get("freeinput") == "true") return true;
    Rails.stopEverything(e);
    return false;
  }
}
