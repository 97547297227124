import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    for (let i = 0; i < this.tabTargets.length; i++) {
      const element = this.tabTargets[i]
      if(element.checked)
        this.show(element.value)
    }
  }

  change(e) {
    this.show(e.target.value)
  }

  show(tab) {
    for (let i = 0; i < this.contentTargets.length; i++) {
      const element = this.contentTargets[i]
      if(element.dataset.tab == tab) {
        element.classList.remove("d-none")
        this.setInputsEnabled(element, false)
      } else {
        element.classList.add("d-none")
        this.setInputsEnabled(element, true)
      }
    }
  }

  setInputsEnabled(element, value) {
    element.querySelectorAll("input, select, textarea").forEach(function(input) {
      input.disabled = value
    })
  }
}
