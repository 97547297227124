import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []
  static values = { delay: Number }

  connect() {
    this.timeout = setTimeout(() => { this.refresh() }, this.delay())
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  delay() {
    return this.delayValue > 0 ? this.delayValue : 1000
  }

  refresh() {
    app.reload()
  }
}
